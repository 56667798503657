import { Modal } from "antd";
import styled from "styled-components";

export const CommonModalStyle = styled(Modal)`
  .ant-modal-content {
    margin: auto !important;;
    background-color: ${({ theme }) => theme.background.main};
  }

  .ant-modal-title {
    background-color: ${({ theme }) => theme.background.main};
    color: ${({ theme }) => theme.primary.dark};
  }
`;
