import { Select } from "antd";
import { Navbar } from "components";
import useQueryHook from "hooks/useQueryHook";
import { IAcademicYear } from "pages/settings/academic-years/types";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface ITableNavbarProps {
  data: IAcademicYear[];
}

export const TableNavbar: FC<ITableNavbarProps> = ({ data }) => {
  const { AddQueryParams, QueryParams } = useQueryHook();
  const { t } = useTranslation();
  return (
    <Navbar
      title={t("general.monthly_payment")}
      actionButtons={
        <div>
          <Select
            className="h-11"
            options={data.map((item: IAcademicYear) => ({
              label: `${new Date(item.startsAt).toLocaleDateString(
                "ru"
              )}-${new Date(item.endsAt).toLocaleDateString("ru")}`,
              value: item._id,
            }))}
            onChange={(value) => {
              const foundAcademicYear = data?.find((item) => item._id === value);
              AddQueryParams({ selectedYear: foundAcademicYear?._id });
            }}
            defaultValue={
              data.find(
                (item: IAcademicYear) =>
                  new Date(item.startsAt) <= new Date() &&
                  new Date(item.endsAt) >= new Date()
              )?._id || QueryParams?.selectedYear
            }
          />

        </div>
      }
    />
  );
};
