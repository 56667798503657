import { Button } from "@mui/material";
import styled from "styled-components";

interface IStyledButtonStyled {
	bg?: string;
	tint?: string;
	height?: number;
	props?: any;
}
export const StyledButtonStyled = styled(Button)<IStyledButtonStyled>`
  background-color: ${({ bg }) => (bg ? bg : "#FF8A32")} !important;
  color: ${({ tint }) => (tint ? tint : "white")} !important;
  width: max-content !important;
  min-width: 142px !important;
  height: ${({ height }) => (height ? height : 48)}px !important;
  font-size: 15px !important;
  border-radius: 12px !important;

  &:disabled {
    background-color: #f5f5f5 !important;
    color: #999 !important;
  }
`;
