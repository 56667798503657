import { TableCell, TableRow } from "components";
import dayjs from "dayjs";
import { useGetMonths } from "hooks/useGetMonths";
import { get } from "lodash";
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export interface IMonthlyPaymentTableHeadProps { }

export const MonthlyPaymentTableHead: FC<IMonthlyPaymentTableHeadProps> = () => {
  const { t } = useTranslation();
  const months = useGetMonths();

  const scrollToCurrentMonth = () => {
    const currentMonth = dayjs().month();
    const currentYear = dayjs().year();

    const foundItem = months.find((attend: Record<string, any>) => {
      const attendTime = dayjs().month(attend.monthNumber - 1).year(attend.year);
      return attendTime.month() === currentMonth && attendTime.year() === currentYear;
    });

    if (foundItem) {
      const element = document.getElementById(`attend-${foundItem.monthNumber}-${foundItem.year}`);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", inline: "center" });
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (months.length > 0) {
      scrollToCurrentMonth();
    }
  }, [months]);

  return (
    <>
      <TableRow>
        <TableCell align="center" className="font-bold sticky left-0 z-10 bg-[#F5F5F5]" rowSpan={2}>
          №
        </TableCell>
        <TableCell align="center" className="font-bold sticky left-[30px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.CONTRACT_NUMBER")}
        </TableCell>
        <TableCell align="center" className="font-bold sticky left-[130px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.NAME_SURNAME")}
        </TableCell>
        <TableCell align="center" className="font-bold sticky left-[240px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.CLASS")}
        </TableCell>
        <TableCell align="center" className="font-bold sticky left-[280px] z-10 bg-[#F5F5F5]" rowSpan={2}>
          {t("TABLE.PHONE_NUMBER")}
        </TableCell>
        {months.map((item) => (
          <TableCell id={`attend-${item.monthNumber}-${item.year}`} align="center" className="font-bold" colSpan={2} key={`${item.monthNumber}-${item.year}`}>
            <p className="text-nowrap"> {`${item.month} ${item.year}`}</p>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {months.map(() => (
          <>
            <TableCell className="font-bold" align="center">
              {t("general.status")}
            </TableCell>
            <TableCell className="font-bold" align="center">
              {t("general.comment")}
            </TableCell>
          </>
        ))}
      </TableRow>
    </>
  );
};
