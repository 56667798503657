import {
  AnaliticIcon,
  ChatIcon,
  DashboardReportIcon1,
  DashboardReportIcon2,
  DashboardReportIcon3,
  DashboardReportIcon4,
  FinanceIcon,
  JournalIcon,
  ManageIcon,
  MonthlyAssessment,
  SchoolIcon,
  SeasionalAssignment,
  SettingIcon,
  TaskIcon,
  TimeTableIcon,
} from "assets/svgs";
import { ISidebarRoute } from "../sidebar.types";
import { SeasionalAssestment } from "pages/SeasionalAssesment";

export const sidebarRoutes: ISidebarRoute[] = [
  // ? dashboard

  {
    icon: <DashboardReportIcon1 />,
    title: "Dashboard",
    path: "/dashboard",
    translate: "DASHBOARD",
    role: "_id",
  },

  // ? schedule

  {
    icon: <TimeTableIcon />,
    title: "Dars jadval",
    path: "/schedule",
    translate: "TIME_TABLE",
    role: "getLessons",
  },

  // ? task

  // {
  //   icon: <TaskIcon />,
  //   title: "Topshiriqlar",
  //   path: "/task",
  //   translate: "TASK",
  //   role: "_id", //! must change
  // },

  // ? school

  {
    icon: <SchoolIcon />,
    title: "O'quv bo'limi",
    translate: "STUDY_DEPARTMENT",
    items: [
      // {
      //   title: "Sinf",
      //   path: "/class",
      //   translate: "BARN_ALL.CLASS",
      //   role: "getClasses",
      // },
      // {
      //   title: "Fanlar",
      //   path: "/subjects",
      //   translate: "BARN_ALL.SCIENCE",
      //   role: "getSubjects",
      // },
      // {
      //   title: "Xonalar",
      //   path: "/rooms",
      //   translate: "BARN_ALL.ROOMS",
      //   role: "getRooms",
      // },
      {
        title: "O'quvchilar",
        path: "/students",
        translate: "BARN_ALL.STUDENTS",
        role: "getStudents",
      },
      // {
      //   title: "Arxiv o'quvchilar",
      //   path: "/archive-students",
      //   translate: "BARN_ALL.ARCHIVE_STUDENTS",
      //   role: "getStudents",
      // },
      // {
      //   title: "Ota onalar",
      //   path: "/parents",
      //   translate: "BARN_ALL.PARENTS",
      //   role: "getParents",
      // },
    ],
  },

  // ? journal

  {
    icon: <JournalIcon />,
    title: "Jurnal",
    path: "/journal",
    translate: "JOURNAL",
    role: "getJournal",
  },
  // {
  //   icon: <ChatIcon />,
  //   title: "Chat",
  //   path: "/chat",
  //   translate: "CHAT",
  //   role: "_id", //! must change
  // },

  // ? monthly-assessment

  // {
  //   icon: <MonthlyAssessment />,
  //   title: "Oylik baholash",
  //   path: "/monthly-assessment",
  //   translate: "MONTHLY_ASSESSMENT",
  //   role: "getSeasonalMarks",
  // },

  // {
  //   icon: (
  //     <div className="h-6 w-6">
  //       <SeasionalAssignment />
  //     </div>
  //   ),
  //   title: "Mavsumiy baholash (fanlar kesimida)",
  //   path: "/seasional-assessment",
  //   translate: "SEASIONAL_ASSESSMENT_BY_SUBJECTS",
  //   role: "getSeasonalMarks",
  // },

  // ? managment

  {
    icon: <ManageIcon />,
    title: "Boshqaruv",
    translate: "MANAGMENT",
    items: [
      {
        title: "Filiallar",
        path: "/branch",
        role: "getBranch",
        translate: "SETTINGS_ALL.Filiallar",
      },
      {
        title: "Ruxsatlar",
        path: "/roles",
        role: "getRoles",
        translate: "SETTINGS_ALL.ALLOWED",
      },
      {
        title: "Xodimlar",
        path: "/employees",
        translate: "SETTINGS_ALL.EMPLOYEE",
        role: "getEmployees",
      },
    ],
  },

  {
    icon: <AnaliticIcon />,
    title: "Analitics",
    translate: "ANALITICS",
    items: [
      {
        title: "O'zlashtirish",
        path: "/analitics/percentile",
        role: "getLearningProgressAnalitics",
        translate: "SETTINGS_ALL.PERCENTILE",
      },
      {
        title: "O'zlashtirish",
        path: "/analitics/rating",
        role: "getClassRatingAnalitics",
        translate: "SETTINGS_ALL.RATING",
      },
      {
        title: "O'zlashtirish",
        path: "/analitics/homework",
        role: "_id",
        translate: "SETTINGS_ALL.HOMEWORK",
      },
      // {
      //   title: "Sinflar reytingi",
      //   path: "/analitics/class-rating",
      //   role: "_id",
      //   translate: "SETTINGS_ALL.CLASS_RATING",
      // },
      // {
      //   title: "homework",
      //   path: "/analitics/homework",
      //   role: "_id",
      //   translate: "SETTINGS_ALL.HOMEWORK",
      // },
    ],
  },

  // ? settings

  {
    icon: <SettingIcon />,
    title: "Sozlamalar",
    translate: "SETTINGS",
    items: [
      {
        title: "Sinf",
        path: "/class",
        translate: "BARN_ALL.CLASS",
        role: "getClasses",
      },
      {
        title: "Group",
        path: "/group",
        translate: "SETTINGS_ALL.GROUPS",
        role: "getReceipt", // must change
      },
      {
        title: "Fanlar",
        path: "/subjects",
        translate: "BARN_ALL.SCIENCE",
        role: "getSubjects",
      },
      {
        title: "Xonalar",
        path: "/rooms",
        translate: "BARN_ALL.ROOMS",
        role: "getRooms",
      },
      {
        title: "Binolar",
        path: "/buildings",
        role: "getBuilding", // @role
        translate: "SETTINGS_ALL.BUILDING",
      },
      {
        title: "Oylik to'lov",
        path: "/monthly-payment",
        role: "viewPayment",
        translate: "SETTINGS_ALL.MONTHLY_PAYMENT",
      },
      {
        title: "Dars soatlari",
        path: "/lesson-time",
        role: "getPeriod",
        translate: "SETTINGS_ALL.LESSON_TIME",
      },
      {
        title: "Payment status",
        path: "/payment-status",
        role: "viewPaymentStatus",
        translate: "SETTINGS_ALL.PAYMENT_STATUS",
      },
      {
        title: "Baholash tizimi",
        path: "/evalution-system",
        translate: "SETTINGS_ALL.EVALUTION_SYSTEM",
        role: "getMarksSystem",
      },
      // {
      //   title: "Tranzaksiya turi",
      //   path: "/transaction-type",
      //   translate: "SETTINGS_ALL.TRANSACTION_TYPE",
      //   role: "transactionTypeGet",
      // },
      // {
      //   title: "To'lov usuli",
      //   path: "/payment-method",
      //   translate: "SETTINGS_ALL.PAYMENT_METHOD",
      //   role: "paymentMethodsGet",
      // },
      // {
      //   title: "Aboniment",
      //   path: "/aboniment",
      //   translate: "SETTINGS_ALL.SUBSCRIPTION",
      //   role: "subscriptionGet",
      // },
      {
        title: "Holiday",
        path: "/holiday",
        translate: "SETTINGS_ALL.HOLIDAY",
        role: "subscriptionGet",
      },
      // {
      //   title: "Receipt",
      //   path: "/receipt",
      //   translate: "SETTINGS_ALL.RECEIPT",
      //   role: "getReceipt",
      // },
      // {
      //   title: "Sms Templates",
      //   path: "/sms-templates",
      //   translate: "SETTINGS_ALL.SMS_TEMPLATES",
      //   role: "getSmsTemplate",
      // },
      // {
      //   title: "Integrations",
      //   path: "/integrations",
      //   translate: "SETTINGS_ALL.INTEGRATIONS",
      //   role: "getIntegration",
      // },

      {
        title: "Academic years",
        path: "/academic-years",
        translate: "SETTINGS_ALL.ACADEMIC_YEARS",
        role: "viewAcademicYear",
      },
      {
        title: "Reasons",
        path: "/reasons",
        translate: "SETTINGS_ALL.REASONS",
        role: "getReceipt", // must change
      },
      {
        title: "News",
        path: "/news",
        translate: "SETTINGS_ALL.NEWS",
        role: "getReceipt", // must change
      },
      {
        title: "Library",
        path: "/library",
        translate: "SETTINGS_ALL.LIBRARY",
        role: "getReceipt", // must change
      },
      {
        title: "Exam",
        path: "/exam",
        translate: "SETTINGS_ALL.EXAM",
        role: "getReceipt", // must change
      },
      {
        title: "Scholarship",
        path: "/scholarship",
        translate: "SETTINGS_ALL.SCHOLARSHIP",
        role: "getReceipt", // must change
      },
    ],
  },
];

export const sidebarTeacherRoutes: ISidebarRoute[] = [
  // ? time-table

  {
    icon: <TimeTableIcon />,
    title: "Dars jadval",
    path: "/schedule",
    translate: "TIME_TABLE",
    role: "getLessons",
  },

  // ? journal

  {
    icon: <JournalIcon />,
    title: "Jurnal",
    path: "/journal",
    translate: "JOURNAL",
    role: "getClasses",
  },

  // ? chat

  // {
  //   icon: <ChatIcon />,
  //   title: "Chat",
  //   path: "/chat",
  //   translate: "CHAT",
  //   role: "_id", //! must change
  // },

  // ? monthly-assessment

  {
    icon: <MonthlyAssessment />,
    title: "Oylik baholash",
    path: "/monthly-assessment",
    translate: "MONTHLY_ASSESSMENT",
    role: "getSeasonalMarks",
  },
];
